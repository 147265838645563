<template>
  <div id="app">
    <b-form class="m-4 form" @sumbit.prevent>
      <b-form-group
          id="savings"
          label="Initial savings:"
          label-for="savings-input"
          class="mr-2"
      >
        <b-form-input
            id="savings-input"
            v-model="savings"
            type="number"
            step="10000"
            number
        ></b-form-input>
      </b-form-group>

      <b-form-group
          id="rent"
          label="Monthly Rent:"
          label-for="rent-input"
          class="mr-2"
      >
        <b-form-input
            id="rent-input"
            v-model="rent"
            type="number"
            step="100"
            number
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="real-monthly-payment"
          label="Real monthly payment:"
          label-for="real-monthly-payment-input"
          class="mr-2"
      >
        <b-form-input
            id="real-monthly-payment-input"
            v-model="realMonthlyPayment"
            type="number"
            step="100"
            number
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Date for Stamp Duty">
        <b-form-radio-group
          id="sdlt-date"
          v-model="sdltDate"
          :options="sdltDateOpts"
          name="sdlt-date"
          stacked
        ></b-form-radio-group>
      </b-form-group>

      <b-form-checkbox
          id="sdlt-second-prop"
          v-model="sdltSecondProp"
          name="sdlt-second-prop"
      >
        This wil be a second property
      </b-form-checkbox>
    </b-form>
    <div class="small">
      <highcharts class="chart" ref="chart" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => {
    return {
      termYrs: 25,
      savings: 100000,
      rent: 1500,
      realMonthlyPayment: 5000,
      sdltSecondProp: false,
      sdltDate: "holiday",
      sdltDateOpts: [
        {
          text: "Up to 30th Jun 2021",
          value: "holiday",
        },
        {
          text: "Between 1st Jul and 20th Sep 2021",
          value: "halfholiday",
        },
        {
          text: "After 1st Oct 2021",
          value: "full",
        }
      ],
      chartOptions: {
        chart: {
          height: 300,
        },
        title: {
          text: "Mortgage",
        },
        tooltip: {
          shared: true,
          headerFormat: 'House price: <b>£{point.key}</b><br /><br />',
        },
        yAxis: [
          {
            title: {
              text: "SDLT/Deposit/Salary (£)",
            },
          },
          {
            title: {
              text: "Loan-To-Value (%)",
            },
          },
          {
            title: {
              text: "Interest rate (%)",
            },
          },
          {
            title: {
              text: "Monthly Payment (£)",
            },
          },
          {
            title: {
              text: "Payoff Time (years)",
            },
          },
        ],
        series: []
      },
      calcTimeout: undefined,
    }
  },
  mounted() {
    this.chartOptions.chart.height = document.documentElement.clientHeight * 0.55 + "px"
    this.updateData()
  },
  watch: {
    savings() {
      this.updateData()
    },
    rent() {
      this.updateData()
    },
    sdltDate() {
      this.updateData()
    },
    sdltSecondProp() {
      this.updateData()
    },
    realMonthlyPayment() {
      this.updateData()
    }
  },
  methods: {
    interestRate(ltv) {
      if (ltv <= 60) return 1.15
      if (ltv <= 75) return 1.45
      if (ltv <= 80) return 2.3
      if (ltv <= 85) return 3
      return undefined
    },
    sdlt(price) {
      let sd = 0
      if (this.sdltSecondProp) sd += price * 0.03
      sd += Math.max(0, price - 1500000) * 0.12
      price = Math.min(1500000, price)
      sd += Math.max(0, price - 925000) * 0.10
      price = Math.min(925000, price)

      if (this.sdltDate === "holiday") {
        price -= 500000
      } else if (this.sdltDate === "halfholiday") {
        price -= 250000
      } else {
        price -= 125000
      }
      sd += Math.max(0, price) * 0.05

      return sd
    },
    monthly(loan, interestPct) {
      const interest = interestPct / 100
      const interest_monthly = interest / 12
      const payments = this.termYrs * 12
      return loan * interest_monthly * ((interest_monthly + 1) ** payments) / (((interest_monthly + 1) ** payments) - 1)
    },
    realTermYears(loan, interestPct) {
      const interestRateMonthly = interestPct / 100 / 12
      let totalInterest = 0
      let savings = 0
      let months = 0
      const interestMonthly = loan * interestRateMonthly
      if (this.realMonthlyPayment <= interestMonthly) {
        return undefined
      }
      while (savings < (loan + totalInterest)) {
        totalInterest += interestMonthly
        savings += this.realMonthlyPayment
        months++
        if (months >= this.termYrs * 12) {
          return undefined
        }
      }
      return months / 12
    },
    payoffMonths(loan, monthly, interestRate, sdlt) {
      let loanLeft = loan
      let rentSum = 0
      let overpay = sdlt
      let months = 0
      const interest_monthly = interestRate / 100 / 12
      while (loanLeft > 0 && rentSum < overpay) {
        const interest = loanLeft * interest_monthly
        overpay += interest
        loanLeft -= monthly - interest
        rentSum += this.rent
        months++
      }
      return months
    },
    updateData () {
      if (this.calcTimeout) clearTimeout(this.calcTimeout)

      const sdltData = []
      const depositData = []
      const salaryData = []
      const ltvData = []
      const interestData = []
      const monthlyData = []
      const payoffData = []
      const realTermData = []

      const finalise = () => {
        this.calcTimeout = undefined
        this.chartOptions.series = [
          {
            name: "Stamp Duty",
            yAxis: 0,
            data: sdltData,
            tooltip: {
              valuePrefix: "£",
            },
          },
          {
            name: "Deposit",
            yAxis: 0,
            data: depositData,
            tooltip: {
              valuePrefix: "£",
            },
          },
          {
            name: "Net Salary Needed (£/year)",
            yAxis: 0,
            data: salaryData,
            tooltip: {
              valuePrefix: "£",
              valueSuffix: " per year",
              valueDecimals: 0,
            },
          },
          {
            name: "Loan-To-Value",
            yAxis: 1,
            data: ltvData,
            tooltip: {
              valueSuffix: "%",
              valueDecimals: 2,
            },
          },
          {
            name: "Interest Rate",
            yAxis: 2,
            data: interestData,
            tooltip: {
              valueSuffix: "%",
            },
          },
          {
            name: "Monthly Payment",
            yAxis: 3,
            data: monthlyData,
            tooltip: {
              valuePrefix: "£",
              valueDecimals: 2,
            },
          },
          {
            name: "Payoff Years",
            yAxis: 4,
            data: payoffData,
            tooltip: {
              valueSuffix: " years",
              valueDecimals: 1,
            },
          },
          {
            name: "Real Term",
            yAxis: 4,
            data: realTermData,
            tooltip: {
              valueSuffix: " years",
              valueDecimals: 1,
            },
          },
        ]
        this.$refs.chart.chart.hideLoading();
      }

      const calcForPrice = (price) => {
        const sdlt = this.sdlt(price)
        const deposit = this.savings - sdlt
        const loan = price - deposit
        const ltv = 100 * loan / price
        const interestRate = this.interestRate(ltv);
        if (!interestRate) {
          finalise()
          return
        }
        const realTermYears = this.realTermYears(loan, interestRate)
        const monthly = this.monthly(loan, interestRate)
        const payoffYears = this.payoffMonths(loan, monthly, interestRate, sdlt) / 12
        depositData.push([price, deposit])
        sdltData.push([price, sdlt])
        salaryData.push([price, loan / 4.5])
        ltvData.push([price, ltv])
        interestData.push([price, interestRate])
        monthlyData.push([price, monthly])
        payoffData.push([price, payoffYears])
        realTermData.push([price, realTermYears])
        this.calcTimeout = setTimeout(() => {calcForPrice(price + 10000)}, 0)
      }
      this.$refs.chart.chart.showLoading();
      this.calcTimeout = setTimeout(() => {calcForPrice(this.savings)}, 0)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}

.form {
  max-width: 400px;
}
</style>
